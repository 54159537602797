import { FlexBox, theme, trackEvent } from '@rario/shared-components'
// import { CustomGutterSpaceRow } from 'components/Layout/Layout.styles'
import dynamic from 'next/dynamic'
import { FunctionComponent, useEffect } from 'react'
import { MobileHomePage } from 'interfaces/homePage'
import Header from 'components/Header'
import HomeLandingFooter from 'components/HomeLanding/HomeLandingFooter'
// import FirstFoldCarousel from './FirstFoldCarousel'
// import HomePageWidgets from './HomePageWidgets'
// import LatestSalesListing from 'components/LatestSaleWidget/LatestSaleWidget'
import ShutdownWidget from './ShutdownWidget'

const NavigationBar = dynamic(() => import('components/NavigationBar'))
// const StickyFloatingButton = dynamic(() => import('components/StickyFloatingButton'))
// const HowRarioWorksCard = dynamic(() => import('components/HowRarioWorksCard'))
// const LearnAboutD3 = dynamic(() => import('components/LearnAboutD3'))
// const PracticeMatchBanner = dynamic(() => import('components/WelcomeScreen/PracticeMatchBanner'))

const HomePage: FunctionComponent<{
  homePageData: MobileHomePage
  showAppDownloadStrip?: boolean
}> = () =>
  // { homePageData /* showAppDownloadStrip = false */ }
  {
    useEffect(() => {
      trackEvent('Section Viewed', 'track', {
        sub_category: 'homepage-special_app_hero_cards',
        type: 'carousel_cards',
        view_type: 'carousel_cards',
        category: 'homepage',
      })

      // if (isRestricted) {
      //   setShowRestrictedUserModal(true)
      // }
      // To trigger scroll handler on load
      document.documentElement.scrollTo({ top: 1, behavior: 'smooth' })
    }, [])

    return (
      <>
        {/* {showAppDownloadStrip && PLATFORM_ORIGIN === ThemeEnum.RARIO && (
        <FlexBox
          position={'relative'}
          cursor={'pointer'}
          height={'auto'}
          width={'100%'}
          maxWidth={theme.maxDesktopLayoutWidth}
          mx={'auto'}
          onClick={() => window && window.open(RARIO_WEBVIEW_APK_URL, '_parent')}
        >
          <Link href={'/app-download#installationSteps'} scroll={false}>
            <Image
              src={getCDNUrl('/App/images/rario-landing/homepage-download-strip.png', true)}
              height={'100%'}
              width={'100%'}
              name={'App Download Strip'}
            />
          </Link>
        </FlexBox>
      )} */}
        <FlexBox
          position={'absolute'}
          flexDirection="column"
          width="100%"
          maxWidth={['100%', theme.maxDesktopLayoutWidth]}
          top={'0px'}
        >
          <Header />
        </FlexBox>

        <FlexBox
          flexDirection="column"
          width="100%"
          mx="auto"
          minHeight="500px"
          overflow="hidden"
          margin="auto"
        >
          {/* <FirstFoldCarousel data={homePageData} /> */}

          {/* <StickyFloatingButton /> */}

          <NavigationBar />

          {/* <LatestSalesListing /> */}

          <FlexBox flexDirection="column" mx="16px" mt="95px">
            <ShutdownWidget variant="marketplace" />
            <FlexBox mt="18px" mb="6px">
              <ShutdownWidget variant="d3" />
            </FlexBox>
          </FlexBox>

          {/* <FlexBox flexDirection={'column'} width={'100%'} justifyContent={'center'}>
          {PLATFORM_ORIGIN === ThemeEnum.RARIO && <D3TrialGame />}
          <PlayBigWinBig />
        </FlexBox> */}
          {
            // Not Required
            /* <ArcadeGameNudge /> */
          }

          {/* <HomePageWidgets /> */}
        </FlexBox>

        {/* <HowRarioWorksCard />
      <LearnAboutD3 />

      <CustomGutterSpaceRow flexDirection={'column'} margin="0 20px 10px">
        <TextField
          textTransform="uppercase"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          color={theme.colors.customWhite}
          fontWeight={800}
          fontSize={'40px'}
          lineHeight={'42px'}
        >
          INVEST IN CRICKETERS
        </TextField>
        <TextField
          textTransform="uppercase"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          color={theme.colors.customWhite}
          fontWeight={800}
          fontSize={'20px'}
          lineHeight={'32px'}
        >
          WIN BIG REWARDS
        </TextField>
      </CustomGutterSpaceRow> */}

        <FlexBox mb={'100px'}>
          <HomeLandingFooter showSocialLinks={true} />
        </FlexBox>
        {/* {!mobileView && PLATFORM_ORIGIN === ThemeEnum.RARIO && <DownloadBanner />} */}
        {/* {showPracticeBanner && (
        <PracticeMatchBanner showPracticeBannerCallback={showPracticeBannerCallback} />
      )} */}
      </>
    )
  }

export default HomePage
