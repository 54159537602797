import { FlexBox, Image, TextField, theme } from '@rario/shared-components'
import { getCDNUrl } from 'shared-components/utils'

const ShutdownWidget: React.FC<{
  variant: 'marketplace' | 'd3'
}> = ({ variant }) => {
  return (
    <FlexBox
      padding="14px 20px"
      alignItems="center"
      justifyContent="center"
      borderRadius="12px"
      background={theme.colors.whites[3]}
    >
      {variant === 'marketplace' ? (
        <Image
          src={getCDNUrl('/App/images/marketplace/marketplace_close.png')}
          height="123.57px"
          width="123.57px"
        />
      ) : (
        <Image
          src={getCDNUrl('/App/images/home/d3-shutdown-closed.png')}
          height="123.57px"
          width="116px"
        />
      )}

      <FlexBox flexDirection="column" ml="25px">
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="600"
          fontSize="16px"
          lineHeight="22px"
          color={theme.colors.greyishWhite}
        >
          {variant === 'marketplace'
            ? 'Marketplace has been suspended.'
            : 'The last game on D3 has been hosted.'}
        </TextField>

        <TextField
          fontWeight="400"
          fontSize="12px"
          lineHeight="16px"
          color={theme.colors.lightGrey}
        >
          {variant === 'marketplace'
            ? "Your cards are visible in your Vault if you haven't opted for Buyback."
            : 'You can still view your previously joined games and activity history.'}
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

export default ShutdownWidget
